import {
  EVENTS_APP_ID,
  EVENTS_MEMBERS_WIDGET_ID,
  GROUPS_APP_ID,
  GROUPS_SECTION_ID,
  MEMBERS_AREA_V2_APP_ID,
  MEMBERS_AREA_V2_SECTION_ID,
  MEMBERS_INFO_APP_ID,
  MEMBERS_INFO_SECTION_ID,
  MEMBERS_PAGE_SECTION_ID,
  PAID_PLANS_APP_ID,
  PAID_PLANS_SECTION_ID,
  SCHEDULE_SECTION_ID,
} from '@wix/wix-events-commons-statics'
import type {IWixAPI, IWixStatic} from '@wix/yoshi-flow-editor'
import {getMembersAPI} from '../utils/members-api'

export const isPaidPlansInstalled = (wixCodeApi: IWixAPI) =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
  )

export const isMembersInstalled = async (Wix: IWixStatic): Promise<boolean> => {
  try {
    const api = await getAppApi(Wix)
    return api.isMembersAreaInstalled()
  } catch (error) {
    console.error(error)
    return false
  }
}

export const isEventsInMembersInstalled = async (Wix: IWixStatic) =>
  Boolean((await Promise.all([isEventsMembersPageInstalled(Wix), isEventsMembersSlotInstalled(Wix)])).find(Boolean))

export const isAppSectionInstalled = (
  Wix: IWixStatic,
  {sectionId, appDefId}: {sectionId: string; appDefId: string},
): Promise<boolean> =>
  new Promise(resolve => {
    Wix.isAppSectionInstalled(sectionId, {appDefinitionId: appDefId}, resolve)
  })

const isEventsMembersPageInstalled = (Wix: IWixStatic) =>
  isAppSectionInstalled(Wix, {
    appDefId: EVENTS_APP_ID,
    sectionId: MEMBERS_PAGE_SECTION_ID,
  })

const isEventsMembersSlotInstalled = async (Wix: IWixStatic): Promise<boolean> => {
  try {
    const api = await getAppApi(Wix)
    return api.isEventsInMembersInstalled()
  } catch {
    // isEventsMembersInstalled always throws if slot is not installed, so not logging error
    return false
  }
}

const getAppApi = (Wix: IWixStatic, retry = 0): Promise<EventsApi> => {
  const MAX_GET_APP_API_RETRIES = 3
  /*
      Wix.Settings.getAppAPI is using some library internally, that is lazy loaded (window.pmrcp).
      If we call this on our app load, Wix.Settings.getAppAPI can hang due to missing library, never resolve or reject.
      Even one retry seems to help consistently.
     */
  return new Promise((resolve, reject) => {
    const retryTimeout = setTimeout(async () => {
      const nextRetry = retry + 1
      if (nextRetry <= MAX_GET_APP_API_RETRIES) {
        try {
          console.log(`Retrying getAppApi, retry: ${nextRetry}`)
          const api = await getAppApi(Wix, nextRetry)
          resolve(api)
        } catch (e) {
          reject(e)
        }
      } else {
        reject(new Error('getAppApi timeout'))
      }
    }, 300)
    Wix.Settings.getAppAPI(
      (api: EventsApi) => {
        clearTimeout(retryTimeout)
        resolve(api)
      },
      error => {
        console.error(error)
        clearTimeout(retryTimeout)
        reject(error)
      },
    )
  })
}

export const isMembersInstalledWixCodeApi = async (wixCodeApi: IWixAPI) => {
  return (
    await Promise.all([
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: MEMBERS_INFO_SECTION_ID,
        appDefinitionId: MEMBERS_INFO_APP_ID,
      }),
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: MEMBERS_AREA_V2_SECTION_ID,
        appDefinitionId: MEMBERS_AREA_V2_APP_ID,
      }),
      isMembersEventsSlotInstalled(wixCodeApi),
      wixCodeApi.site.isAppSectionInstalled({
        appDefinitionId: EVENTS_APP_ID,
        sectionId: MEMBERS_PAGE_SECTION_ID,
      }),
    ])
  ).find(Boolean)
}

export const isEventsInMembersInstalledWixCodeApi = async (wixCodeApi: IWixAPI) =>
  (
    await Promise.all([
      isMembersEventsSlotInstalled(wixCodeApi),
      wixCodeApi.site.isAppSectionInstalled({
        appDefinitionId: EVENTS_APP_ID,
        sectionId: MEMBERS_PAGE_SECTION_ID,
      }),
    ])
  ).find(Boolean)

const isMembersEventsSlotInstalled = async (wixCodeApi: IWixAPI) => {
  try {
    const api = await getMembersAPI(wixCodeApi)
    const response = await api.isAppSectionInstalled({widgetId: EVENTS_MEMBERS_WIDGET_ID})
    return response
  } catch (e) {
    // getSectionUrl always throws if widget is not installed (members should fix it)
    // console.error(e)
  }
  return false
}

export const getInstalledAppsState = async (wixCodeApi: IWixAPI) => {
  const [members, schedule, groups, paidPlans, eventsInMembersInstalled] = await Promise.all([
    isMembersInstalledWixCodeApi(wixCodeApi),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: EVENTS_APP_ID,
      sectionId: SCHEDULE_SECTION_ID,
    }),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: GROUPS_APP_ID,
      sectionId: GROUPS_SECTION_ID,
    }),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
    isEventsInMembersInstalledWixCodeApi(wixCodeApi),
  ])

  return {members, schedule, groups, paidPlans, eventsInMembersInstalled}
}
